import { BehaviorSubject } from 'rxjs';
import { FilterHu } from '@app/leaseplan/models';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FilterService {
	payload = new BehaviorSubject(<FilterHu>{
		'gyarto': null,
		'modell': null,
		'uzemanyag': null,
		'karosszeria': null,
		'motor': null,
		'valto': null,
		'elerhetoseg': null,
		'havidij': null
	});

	clearForm = new BehaviorSubject('');
}

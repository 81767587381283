import { ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '@app-env/environment';


export class SentryErrorHandler implements ErrorHandler {
	constructor() {
		Sentry.init({
			dsn: environment.SERVICE_SENTRY_DSN_PUBLIC,
			environment: environment.APP_ENV,
			release: environment.COMMIT_HASH.substring(0, 8),
			denyUrls: [
				/www\.googletagmanager\.com/,
				/cdn\.cookielaw\.org/,
			],
			ignoreErrors: [
				/^ReportingObserver \[(deprecation|intervention)\]/,
				'ResizeObserver loop limit exceeded',
				'ResizeObserver loop completed with undelivered notifications.',
				'SecurityError: The operation is insecure.',
				'Object Not Found Matching Id:',
				'Object doesn\'t support property or method \'normalize\'',
			],
		});
		Sentry.configureScope((scope) => {
			scope.setTag('logger', 'javascript');
		});
		console.log('sentry init');
	}

	handleError(err: any): void {
		Sentry.captureException(err.originalError || err);
	}
}

export const SENTRY_PROVIDER = {
	provide:	ErrorHandler,
	useClass:	SentryErrorHandler,
};

<div
	*ngIf="notificationOpen | async"
	class="successfully-added-container">
	<div
		class="successfully-added"
		(clickOutside)="closeNotification()">
		<img src="/assets/img/flexiplan/flexi-added-icon.svg" alt>
		<div class="text-container">
			<div>
				<h5 *ngIf="notificationModifying | async; else adding" >Ajánlatkérés sikeresen <br> módosítva</h5>
				<ng-template #adding>
					<h5 >Ajánlatkérés sikeresen <br>hozzáadva</h5>
				</ng-template>
				<a routerLink="/ajanlatkeres">TOVÁBB AZ ADATOK MEGADÁSÁHOZ</a>
			</div>
		</div>
		<div class="close"
			(click)="closeNotification()">
			<img src="/assets/img/flexiplan/icons/close.svg">
		</div>
	</div>
</div>

<div class="container" [attr.data-response-code]="is410 ? 410 : 404">
	<div class="row">
		<div class="col-md-16 offset-md-4">
			<img src="assets/img/car.svg" width="64px" height="64px" class="img-fluid d-block mx-auto" alt="Nem található">
			<h1 class="title-404">Az oldal <span *ngIf="is410">már </span>nem található</h1>
			<p>Az oldal valószínűleg elköltözött vagy már nem elérhető.</p>
			<p>Kérjük, próbálja ki az alábbiakat: <br>Ellenőrizze a megadott weboldalt, hogy helyes-e. <br>Próbáljon közvetlenül a Kezdőlapról hozzáférni az oldalhoz könyvjelző használata helyett. Ha az oldal elköltözött, állítsa vissza a könyvjelzőt. <br>Böngésszen végig a menüben elérhető sok kategória között.</p>
		</div>
	</div>
</div>

<div class="o-wrapper footer__wrapper u-padding-bottom u-padding-top@mobile u-padding-top-huge@tablet">
	<img src="/assets/img/logo-footer-white.png" class="footer__logo u-hide-until@tablet" alt="Tartós bérlet | LeasePlan">
	<div class="o-layout u-padding-bottom-large">
		<div class="o-layout__item u-1/3@tablet u-1/4@desktop">
			<a data-tag-id="footer-column-link-/hu-hu/ajanlatok/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/ajanlatok/">
				<h6 class="u-margin-top u-txt--14 translation-label">Ajánlatok</h6>
			</a>
			<ul class="o-list-bare">
				<li class="o-list-bare__item">
					<a [routerLink]="['/']" class="u-txt--white footer__link">
						<span>Autóink</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a [routerLink]="['/tudastar']" class="u-txt--white footer__link">
						<span>Tartós autóbérlésről</span>
					</a>
				</li>
				<!--<li class="o-list-bare__item">
					<a [routerLink]="['/kalkulator']" class="u-txt--white footer__link">
						<span>LeasePlan kalkulátor</span>
					</a>
				</li>-->
			</ul>
		</div>
		<div class="o-layout__item u-1/3@tablet u-1/4@desktop">
			<a href="https://hasznaltauto.leaseplan.com/" class="u-txt--white footer__link" target="_blank" data-tag-id="footer-column-link-https://hasznaltauto.leaseplan.com/">
				<h6 class="u-margin-top u-txt--14 translation-label">Használt autók</h6>
			</a>
			<ul class="o-list-bare">
				<li class="o-list-bare__item">
					<a href="https://hasznaltauto.leaseplan.com/" class="u-txt--white footer__link" target="_blank" data-tag-id="footer-column-link-https://hasznaltauto.leaseplan.com/">
						<span>Autóink</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a href="https://hasznaltauto.leaseplan.com/" class="u-txt--white footer__link" target="_blank" data-tag-id="footer-column-link-https://hasznaltauto.leaseplan.com/">
						<span>Helyünk</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a href="https://hasznaltauto.leaseplan.com/" class="u-txt--white footer__link" target="_blank" data-tag-id="footer-column-link-https://hasznaltauto.leaseplan.com/">
						<span>Miért a LeasePlan Használt autó</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a href="https://hasznaltauto.leaseplan.com/" class="u-txt--white footer__link" target="_blank" data-tag-id="footer-column-link-https://hasznaltauto.leaseplan.com/">
						<span>Kapcsolat</span>
					</a>
				</li>
			</ul>
		</div>
		<div class="o-layout__item u-1/3@tablet u-1/4@desktop">
			<a data-tag-id="footer-column-link-/hu-hu/flottakezeles/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/flottakezeles/">
				<h6 class="u-margin-top u-txt--14 translation-label">Flottakezelés</h6>
			</a>
			<ul class="o-list-bare">
				<li class="o-list-bare__item">
					<a data-tag-id="footer-column-link-/hu-hu/flottakezeles/szolgaltatasok/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/flottakezeles/szolgaltatasok/">
						<span>Szolgáltatásaink</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a data-tag-id="footer-column-link-/hu-hu/flottakezeles/termekeink/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/flottakezeles/termekeink/">
						<span>Termékeink</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a data-tag-id="footer-column-link-/hu-hu/flottakezeles/miert-a-leaseplan/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/flottakezeles/miert-a-leaseplan/">
						<span>Miért a LeasePlan</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a data-tag-id="footer-column-link-/hu-hu/flottakezeles/allami-szektor/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/flottakezeles/allami-szektor/">
						<span>Állami szektor</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a data-tag-id="footer-column-link-/hu-hu/flottakezeles/nemzetkozi-flotta/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/flottakezeles/nemzetkozi-flotta/">
						<span>Nemzetközi flotta</span>
					</a>
				</li>
			</ul>
		</div>
		<div class="o-layout__item u-1/3@tablet u-1/4@desktop">
			<a data-tag-id="footer-column-link-/hu-hu/autohasznaloknak/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/autohasznaloknak/">
				<h6 class="u-margin-top u-txt--14 translation-label">Autóhasználóinknak</h6>
			</a>
			<ul class="o-list-bare">
				<li class="o-list-bare__item">
					<a data-tag-id="footer-column-link-/hu-hu/autohasznaloknak/karbantartas-javitas/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/autohasznaloknak/karbantartas-javitas/">
						<span>Karbantartás és javítások</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a data-tag-id="footer-column-link-/hu-hu/autohasznaloknak/balesetek-karesetek/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/autohasznaloknak/balesetek-karesetek/">
						<span>Balesetek és káresetek</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a data-tag-id="footer-column-link-/hu-hu/autohasznaloknak/uj-auto-rendelese/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/autohasznaloknak/uj-auto-rendelese/">
						<span>Új autó rendelése</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a data-tag-id="footer-column-link-/hu-hu/autohasznaloknak/az-auto-leadasa/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/autohasznaloknak/az-auto-leadasa/">
						<span>Az autója leadása</span>
					</a>
				</li>
			</ul>
		</div>
		<div class="o-layout__item u-1/3@tablet u-1/4@desktop">
			<a data-tag-id="footer-column-link-/hu-hu/rolunk/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/rolunk/">
				<h6 class="u-margin-top u-txt--14 translation-label">Rólunk</h6>
			</a>
			<ul class="o-list-bare">
				<li class="o-list-bare__item">
					<a data-tag-id="footer-column-link-/hu-hu/rolunk/mi/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/rolunk/mi/">
						<span>A LeasePlanről</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a data-tag-id="footer-column-link-/hu-hu/rolunk/whats-next/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/rolunk/whats-next/">
						<span>What's next?</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a data-tag-id="footer-column-link-/hu-hu/rolunk/allasok/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/rolunk/allasok/">
						<span>Betöltetlen álláshelyek</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a data-tag-id="footer-column-link-/hu-hu/hirek/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/hirek/">
						<span>Hírek</span>
					</a>
				</li>
			</ul>
		</div>
		<div class="o-layout__item u-1/3@tablet u-1/4@desktop">
			<a data-tag-id="footer-column-link-/hu-hu/kapcsolat/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/kapcsolat/">
				<h6 class="u-margin-top u-txt--14 translation-label">Kapcsolat</h6>
			</a>
			<ul class="o-list-bare">
				<li class="o-list-bare__item">
					<a data-tag-id="footer-column-link-/hu-hu/tamogatas/" class="u-txt--white footer__link" rel="follow" href="https://www.leaseplan.com/hu-hu/tamogatas/">
						<span>Támogatás</span>
					</a>
				</li>
			</ul>
		</div>
		<div class="o-layout__item u-1/3@tablet u-1/4@desktop">
			<a href="https://www.leaseplan.com/corporate/" class="u-txt--white footer__link" target="_blank" data-tag-id="footer-column-link-https://www.leaseplan.com/corporate/">
				<h6 class="u-margin-top u-txt--14 translation-label">Corporate site</h6>
			</a>
			<ul class="o-list-bare">
				<li class="o-list-bare__item">
					<a href="https://www.leaseplan.com/corporate/about-us" class="u-txt--white footer__link" target="_blank" data-tag-id="footer-column-link-https://www.leaseplan.com/corporate/about-us">
						<span>About us</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a href="https://www.leaseplan.com/corporate/products-and-services" class="u-txt--white footer__link" target="_blank" data-tag-id="footer-column-link-https://www.leaseplan.com/corporate/products-and-services">
						<span>Products &amp; services</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a href="https://www.leaseplan.com/corporate/investors" class="u-txt--white footer__link" target="_blank" data-tag-id="footer-column-link-https://www.leaseplan.com/corporate/investors">
						<span>Investors</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a href="https://www.leaseplan.com/corporate/sustainability" class="u-txt--white footer__link" target="_blank" data-tag-id="footer-column-link-https://www.leaseplan.com/corporate/sustainability">
						<span>Sustainability</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a href="https://www.leaseplan.com/corporate/news-and-media" class="u-txt--white footer__link" target="_blank" data-tag-id="footer-column-link-https://www.leaseplan.com/corporate/news-and-media">
						<span>News &amp; media</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a href="https://www.leaseplan.com/corporate/investors/annual-report-2017" class="u-txt--white footer__link" target="_blank" data-tag-id="footer-column-link-https://www.leaseplan.com/corporate/investors/annual-report-2017">
						<span>Annual report</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a href="https://www.leaseplan.com/corporate/careers" class="u-txt--white footer__link" target="_blank" data-tag-id="footer-column-link-https://www.leaseplan.com/corporate/careers">
						<span>Careers</span>
					</a>
				</li>
				<li class="o-list-bare__item">
					<a href="https://www.leaseplan.com/corporate/contacts" class="u-txt--white footer__link" target="_blank" data-tag-id="footer-column-link-https://www.leaseplan.com/corporate/contacts">
						<span>Contacts</span>
					</a>
				</li>
			</ul>
		</div>
		<div class="o-layout__item u-1/3@tablet u-1/4@desktop">
			<h6 class="u-margin-top u-txt--14 u-txt--white translation-label">LeasePlan Hungária Zrt. </h6>
			<ul class="o-list-bare">
				<li class="o-list-bare__item footer__address-line translation-label">Bocskai út 134-146</li>
				<li class="o-list-bare__item footer__address-line translation-label">1113 Budapest</li>
				<li class="o-list-bare__item footer__address-line translation-label">Magyarország</li>
			</ul>
		</div>
	</div>
	<div class="o-layout o-layout--auto o-layout--stretch u-padding-bottom">
		<div class="o-layout__item u-padding-bottom-small">
			<div class="o-layout o-layout--flush o-layout--auto u-padding-bottom-small">
				<div class="o-layout__item footer__legal-item">
					<a data-tag-id="footer-legal-link-/hu-hu/sutik/" class="u-txt--white footer__small-txt" rel="follow" href="https://www.leaseplan.com/hu-hu/sutik/">Sütik</a>
				</div>
				<div class="o-layout__item footer__legal-item">
					<a data-tag-id="footer-legal-link-/hu-hu/jogi-nyilatkozat/" class="u-txt--white footer__small-txt" rel="follow" href="https://www.leaseplan.com/hu-hu/jogi-nyilatkozat/">Jogi nyilatkozat</a>
				</div>
				<div class="o-layout__item footer__legal-item">
					<a href="https://app-eu.onetrust.com/app/#/webform/5ccfb19a-96e4-4915-8fd1-59cc18736df4" class="u-txt--white footer__small-txt" target="_blank" data-tag-id="footer-legal-link-https://app-eu.onetrust.com/app/#/webform/5ccfb19a-96e4-4915-8fd1-59cc18736df4">Personal Data Rights</a>
				</div>
				<div class="o-layout__item footer__legal-item">
					<a data-tag-id="footer-legal-link-/hu-hu/adatvedelmi-nyilatkozat/" class="u-txt--white footer__small-txt" rel="follow" href="https://www.leaseplan.com/hu-hu/adatvedelmi-nyilatkozat/">Nemzetközi adatvédelmi szabályzat</a>
				</div>
			</div>
		</div>
	</div>
	<div class="o-layout">
		<div class="footer__small-txt o-layout__item u-1/4@tablet u-txt--white u-txt--12">
			<span class="translation-label">© 2022 LeasePlan</span>
		</div>
	</div>
</div>

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationDisplayService } from '@app/leaseplan/services/notificationDisplay.service';

@Component({
	selector: 'notification-component',
	templateUrl: 'notification.component.html',
	styleUrls: ['notification.component.scss'],
})
export class NotificationComponent implements OnInit {

	notificationOpen: BehaviorSubject<boolean>;
	notificationModifying: BehaviorSubject<any>;

	public savedOffersTotal$: Observable<number>;

	constructor(
		private _router: Router,
		private _notification: NotificationDisplayService,

	) {
		this._router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.closeNotification();
			}
		});
	}

	ngOnInit() {
		this.notificationOpen = this._notification.open;
		this.notificationModifying = this._notification.modifying;
	}

	closeNotification() {
		this._notification.setOpen(false);
		this._notification.setModifying(false);
	}
}

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface LetContext<T> {
	ngxLet: T;
}

@Directive({
	selector: '[ngxLet]',
})
export class NgxLetDirective<T> {
	private _context: LetContext<T> = { ngxLet: null };

	constructor(_viewContainer: ViewContainerRef, _templateRef: TemplateRef<LetContext<T>>) {
		_viewContainer.createEmbeddedView(_templateRef, this._context);
	}

	@Input()
	set ngxLet(value: T) {
		this._context.ngxLet = value;
	}
}

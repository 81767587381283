import { Component } from '@angular/core';
import { SavedOffersCounter } from '@app/leaseplan/services/savedOffersCounter.service';
import { combineLatest, Observable } from 'rxjs';

import { SavedOfferWithDetails } from '@app/leaseplan/models';
import { PostState, CMSService } from '@app/cms';

import { filter, first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromOffers from '@app/leaseplan/store';
import * as fromFlexiplan from '@app/leaseplan/store';
import * as flexiplanActions from '@app/leaseplan/store/actions/flexiplan.actions';


@Component({
	selector: 'offers-summary-btn',
	templateUrl: 'offers-summary-btn.component.html',
	styleUrls: ['offers-summary-btn.component.scss'],
})
export class OffersSummaryBtnComponent {


	public savedOffersTotal$: Observable<number>;

	constructor(
		private _savedOffersCounter: SavedOffersCounter,
	) {
		this.savedOffersTotal$ = this._savedOffersCounter.getTotal$();
	}
}

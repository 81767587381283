import * as actions from '../actions/flexiplan.actions';
import { Flexiplan } from '../../models/flexiplan.model';


export interface State {
	items: Flexiplan[];
	_loading: boolean;
	_loaded: boolean;
	_failed: boolean;
}

const initialState: State = {
	items: [],
	_loading: false,
	_loaded: false,
	_failed: false,
};


export function reducer(state = initialState, action: actions.FlexiplanActions): State {
	if (!action) {
		return state;
	}

	switch (action.type) {

		case actions.FLEXIPLAN_LOAD:
			return {
				...state,
				_loading: true,
			};

		case actions.FLEXIPLAN_LOAD_SUCCESS:
			return {
				...state,
				items: action.payload,
				_loading: false,
				_loaded: true,
				_failed: false,
			};

		case actions.FLEXIPLAN_LOAD_FAILURE:
			return {
				...state,
				items: [],
				_loading: false,
				_failed: true,
			};

		default:
			return state;
	}
}

import { Action } from '@ngrx/store';

export const ENQUIRY_FORM_UPDATE = '[Enquiry form] update';
export const ENQUIRY_FORM_DELETE = '[Enquiry form] delete';

export class EnquiryFormUpdateAction implements Action {
	public readonly type = ENQUIRY_FORM_UPDATE;
	constructor(public payload: any) {}
}

export class EnquiryFormDeleteAction implements Action {
	public readonly type = ENQUIRY_FORM_DELETE;
}

export type EnquiryFormActions
	= EnquiryFormUpdateAction
	| EnquiryFormDeleteAction
;

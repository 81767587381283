import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as actions from '../actions/saved-offers.actions';
import { SavedOffer } from '@app/leaseplan/models';

import { uniqueId } from 'lodash-es';


/**
 * State ID
 */
export const STATE_ID = 'saved-offers';

/**
 * State
 */
export interface State extends EntityState<SavedOffer> {
}

/**
 * Adapter
 */
export const adapter: EntityAdapter<SavedOffer> = createEntityAdapter<SavedOffer>({
	// selectId: offer => offer.id || uniqueId('offer-'),
});

/**
 * Initial state
 */
export const initialState: State = adapter.getInitialState({
});


export function reducer(state = initialState, action: actions.SavedOffersActions): State {
	if (!action) return state;

	switch (action.type) {

		case actions.OFFERS_UPSERT: {
			let { offer } = action.payload;

			offer = {
				...offer,
				...{
					id: offer.id || 'offer-' + (
						state.ids.length ?
							parseInt(state.ids[ state.ids.length - 1].toString().replace(/[^\d]*/, ''), 10) + 1 :
							0
					)
				},
			};

			// offer.id = offer.id || uniqueId('offer-');

			return adapter.upsertOne(offer, state);
		}

		case actions.OFFERS_DELETE: {
			const { id } = action.payload;
			return adapter.removeOne(id, state);
		}

		case actions.OFFERS_DELETE_ALL: {
			return adapter.removeAll(state);
		}

		default:
			return state;
	}
}

<nav class="navbar navbar-light" [class.opened]="!isNavbarCollapsed">
	<a (click)="clearFilter()" class="navbar-brand" [routerLink]="['/']"></a>

	<button
		class="navbar-toggler hamburger hamburger--3dx d-lg-none"
		type="button"
		(click)="isNavbarCollapsed = !isNavbarCollapsed"
		aria-controls="navbarContent"
		[attr.aria-expanded]="!isNavbarCollapsed"
		aria-label="Toggle navigation">
		<span class="menu-text">Menü</span>
		<span class="hamburger-box">
			<span class="hamburger-inner"></span>
		</span>
	</button>

	<!-- desktop -->
	<div class="navbar-desktop d-none d-lg-block">
		<div class="container">
			<ul class="navbar-nav navbar-nav-contact">
				<li class="nav-item">
					<a target="_blank" href="https://www.leaseplan.com/hu-hu/kapcsolat/" class="nav-link" (click)="analyticsClickContactEvent()">
						Kapcsolat
					</a>
				</li>
				<li class="nav-item flag">
					<a target="_blank" href="https://www.leaseplan.com/select-country-language/" class="nav-link">
						<img src="/assets/img/hu.svg" alt="HU">HU
					</a>
				</li>
			</ul>
		</div>

		<div class="navbar-desktop-level-first">
			<div class="container">
				<ul class="navbar-nav navbar-nav-main">
					<li class="nav-item active">
						<a target="_blank" href="https://www.leaseplan.com/hu-hu/ajanlatok/" class="nav-link" (click)="analyticsClickMenuEvent('Ajánlatok', 'https://www.leaseplan.com/hu-hu/ajanlatok/')">
							Ajánlatok
						</a>
					</li>
					<li class="nav-item">
						<a target="_blank" href="https://hasznaltauto.leaseplan.com/" class="nav-link" (click)="analyticsClickMenuEvent('Használt autók', 'https://hasznaltauto.leaseplan.com/')">
							Használt autók
						</a>
					</li>

					<li class="nav-item">
						<a target="_blank" href="https://www.leaseplan.com/hu-hu/flottakezeles/" class="nav-link" (click)="analyticsClickMenuEvent('Flottakezelés', 'https://www.leaseplan.com/hu-hu/flottakezeles/')">
							Flottakezelés
						</a>
					</li>

					<li class="nav-item">
						<a target="_blank" href="https://www.leaseplan.com/hu-hu/autohasznaloknak/" class="nav-link" (click)="analyticsClickMenuEvent('Autóhasználóinknak', 'https://www.leaseplan.com/hu-hu/autohasznaloknak/')">
							Autóhasználóinknak
						</a>
					</li>

					<li class="nav-item">
						<a target="_blank" href="https://www.leaseplan.com/hu-hu/rolunk/" class="nav-link" (click)="analyticsClickMenuEvent('Rólunk', 'https://www.leaseplan.com/hu-hu/rolunk/')">
							Rólunk
						</a>
					</li>
				</ul>
			</div>
		</div>

		<div class="container">
			<ul class="navbar-nav navbar-nav-offers">
				<offers-summary-btn></offers-summary-btn>
			</ul>
		</div>

		<div class="navbar-desktop-level-second">
			<div class="container">
				<ul class="navbar-nav">
					<li class="nav-item" [class.active]="isHomeActive">
						<a [routerLink]="['/']" class="nav-link" (click)="analyticsClickMenuEvent('Tartós bérlet ajánlataink', '/')">
							Tartós bérlet ajánlataink
						</a>
					</li>
					<li class="nav-item" routerLinkActive="active">
						<a [routerLink]="['/szolgaltatasunk']" class="nav-link" (click)="analyticsClickMenuEvent('Szolgáltatásunk', '/szolgaltatasunk')">
							Szolgáltatásunk
						</a>
					</li>
					<li class="nav-item" routerLinkActive="active">
						<a [routerLink]="['/rovidtavu-autoberles']" class="nav-link" (click)="analyticsClickMenuEvent('Rövidtávú autóbérlés', '/rovidtavu-autoberles')">
							Rövidtávú autóbérlés
						</a>
					</li>
					<li class="nav-item" routerLinkActive="active">
						<a [routerLink]="['/tudastar']" class="nav-link" (click)="analyticsClickMenuEvent('Tudástár', '/tudastar')">
							Tudástár
						</a>
					</li>
					<li class="nav-item" routerLinkActive="active">
						<a [routerLink]="['/flottamagazin']" class="nav-link" (click)="analyticsClickMenuEvent('Magazin', '/flottamagazin')">
							Magazin
						</a>
					</li>
					<!--<li class="nav-item" routerLinkActive="active">
						<a [routerLink]="['/kalkulator']" class="nav-link">
							LeasePlan kalkulátor
						</a>
						<a [routerLink]="['/kalkulator-specifikacio']" class="d-none">
							LeasePlan kalkulátor
						</a>
						<a [routerLink]="['/kalkulator-profil']" class="d-none">
							LeasePlan kalkulátor
						</a>
					</li>-->
				</ul>
			</div>
		</div>
	</div>

	<!-- tablet -->
	<div class="navbar-active d-none d-sm-block d-lg-none navbar-tablet">
		<ul class="navbar-nav navbar-nav-main">
			<li class="nav-item" [class.active]="isHomeActive">
				<a [routerLink]="['/']" class="nav-link" (click)="analyticsClickMenuEvent('Tartós bérlet ajánlataink', '/')">
					Tartós bérlet ajánlataink
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active">
				<a [routerLink]="['/szolgaltatasunk']" class="nav-link" (click)="analyticsClickMenuEvent('Szolgáltatásunk', '/szolgaltatasunk')">
					Szolgáltatásunk
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active">
				<a [routerLink]="['/rovidtavu-autoberles']" class="nav-link" (click)="analyticsClickMenuEvent('Rövidtávú autóbérlés', '/rovidtavu-autoberles')">
					Rövidtávú autóbérlés
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active">
				<a [routerLink]="['/tudastar']" class="nav-link" (click)="analyticsClickMenuEvent('Tartós autóbérlésről', '/tudastar')">
					Tartós autóbérlésről
				</a>
			</li>
			<!--<li class="nav-item" routerLinkActive="active">
				<a [routerLink]="['/kalkulator']" class="nav-link">
					LeasePlan kalkulátor
				</a>
				<a [routerLink]="['/kalkulator-specifikacio']" class="d-none">
					LeasePlan kalkulátor
				</a>
				<a [routerLink]="['/kalkulator-profil']" class="d-none">
					LeasePlan kalkulátor
				</a>
			</li>-->
			<offers-summary-btn></offers-summary-btn>
		</ul>
	</div>

	<!-- mobile -->
	<div id="navbarContent" class="navbar-mobile navbar-collapse d-lg-none" [@smoothCollapse]="isNavbarCollapsed ? 'initial' : 'final'">
		<ul class="navbar-nav mr-auto">
			<!--<li class="nav-item">
				<a class="nav-link" target="_blank" href="https://www.leaseplan.com/hu-hu/" target="_blank">Főoldal</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" target="_blank" href="https://www.leaseplan.com/hu-hu/ajanlatok/" target="_blank">Ajánlatok</a>
			</li>-->
			<li class="nav-item">
				<a class="nav-link" target="_blank" href="https://www.leaseplan.com/hu-hu/" target="_blank">LeasePlan</a>
			</li>
		</ul>

		<ul class="navbar-nav navbar-nav-main mr-auto">
			<li class="nav-item" [class.active]="isHomeActive">
				<a [routerLink]="['/']" class="nav-link" (click)="analyticsClickMenuEvent('Tartós bérlet ajánlataink', '/')">
					Tartós bérlet ajánlataink
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active">
				<a [routerLink]="['/szolgaltatasunk']" class="nav-link" (click)="analyticsClickMenuEvent('Szolgáltatásunk', '/szolgaltatasunk')">
					Szolgáltatásunk
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active">
				<a [routerLink]="['/rovidtavu-autoberles']" class="nav-link" (click)="analyticsClickMenuEvent('Rövidtávú autóbérlés', '/rovidtavu-autoberles')">
					Rövidtávú autóbérlés
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active">
				<a [routerLink]="['/tudastar']" class="nav-link" (click)="analyticsClickMenuEvent('Tartós autóbérlésről', '/tudastar')">
					Tartós autóbérlésről
				</a>
			</li>
			<li class="nav-item" routerLinkActive="active">
				<a [routerLink]="['/flottamagazin']" class="nav-link" (click)="analyticsClickMenuEvent('Magazin', '/flottamagazin')">
					Magazin
				</a>
			</li>
			<!--<li class="nav-item" routerLinkActive="active">
				<a [routerLink]="['/kalkulator']" class="nav-link">
					LeasePlan kalkulátor
				</a>
				<a [routerLink]="['/kalkulator-specifikacio']" class="d-none">
					LeasePlan kalkulátor
				</a>
				<a [routerLink]="['/kalkulator-profil']" class="d-none">
					LeasePlan kalkulátor
				</a>
			</li>-->
		</ul>

		<ul class="navbar-nav mr-auto">
			<offers-summary-btn></offers-summary-btn>
		</ul>

		<ul class="navbar-nav mr-auto">
			<li class="nav-item">
				<a class="nav-link" target="_blank" href="https://www.leaseplan.com/hu-hu/kapcsolat/" (click)="analyticsClickContactEvent()">Kapcsolat</a>
			</li>
		</ul>
	</div>
	<notification-component class="d-lg-none"></notification-component>
</nav>

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pluck, map } from 'rxjs/operators';

import { environment } from '@app-env/environment';
import * as fromFlexiplan from '@app/leaseplan/store';
import * as FlexiplanActions from '@app/leaseplan/store/actions/flexiplan.actions';


@Injectable({ providedIn: 'root' })
export class FlexiplanService {

	private readonly endpoint = environment.API_URL + 'posts?type=flexi';

	constructor(
		private _store: Store<fromFlexiplan.State>,
		private http: HttpClient,

	) {
	}

	public getFlexiplans(): Observable<any> {
		return this.http.get(this.endpoint).pipe(
			// tap(response => console.log(`XHR to ${endpoint} finished.`, response)),
			pluck('data'),
			pluck('items'),
		);
	}
}

import { Component, Input, OnInit } from '@angular/core';
import { SharedStore } from '@app-core/services/shared';


@Component({
	selector:		'section[page-404]',
	styleUrls:		[ '404.component.scss' ],
	templateUrl:	'404.component.html',
})
export class NotFoundComponent implements OnInit {
	@Input() is410 = false;

	constructor(
		public shared: SharedStore,
	) {
		this.shared.page = '404';
	}

	ngOnInit() {
		const location = window.location.href.replace(/\?.*/, '').replace(/#.*/, '');

		if (!location.match(/\/404$/)) {
			// window.location.href = location + '/404';
		}
	}
}

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
	previousPage = '';

	pageView = () => {
		const referrer = this.previousPage || window.location.origin;
		const url = window.location.href;

		console.warn('Analytics.pageView()', url, referrer);

		/*
		if ((window as any).gtag !== undefined) {
			(window as any).gtag('event', 'page_view');
		}
		*/

		if ((window as any).fbq !== undefined) {
			(window as any).fbq('track', 'PageView');
		}

		if ((window as any).dataLayer !== undefined) {
			const pageTitle = document.head.querySelector('title').textContent;
			const itemName = pageTitle.replace('Operatív lízing | ALD LeasePlan', '').trim();

			let pageTopic = 'offers';

			if (window.location.pathname.startsWith('/szolgaltatasunk')) {
				pageTopic = 'services';
			} else if (window.location.pathname.startsWith('/rovidtavu-autoberles')) {
				pageTopic = 'flex';
			} else if (window.location.pathname.startsWith('/tudastar')) {
				pageTopic = 'blog';
			} else if (window.location.pathname.startsWith('/flottamagazin')) {
				pageTopic = 'magazin';
			}

			this._dataLayer({
				event: 'PageView',
				meta: {
					location: {
						referrer,
						url,
						hostname: window.location.hostname,
						pathname: window.location.pathname,
						language: 'hu',
						country: 'hu'
					},
					page: {
						itemName,
						displayName: 'autotartosberlet',
						valueStream: 'business lease',
						pageTitle,
						pageId: 'hu-hu | business lease | ' + pageTitle,
						topic: pageTopic,
						// pageCategories: ['home', 'operativ lizing', 'autoink']
					},
					/*
					user: {
						userType: 'driver',
						cid: '',
						userId: ''
					},
					*/
					/*
					action: 'pop',
					isFirstRendering: true,
					platform: '01',
					isSEOOptimized: true,
					phase: 'consider',
					pageType: 'autotartosberlet_pages'
					*/
				}
			});
		}

		this.previousPage = window.location.href;
	};

	clickProduct = (product: any, isFeatured: boolean) => {
		const matomoItem = this._getMatomoProductPayload(product);
		const ga4Item = this._getGa4ProductPayload(product);

		console.warn('Analytics.clickProduct()', matomoItem, ga4Item);

		this._dataLayer({
			event: 'productClick',
			ecommerce: {
				currencyCode: 'HUF',
				value: this._getPrice(product),

				// ga4
				item_list_id: isFeatured ? 'featured' : 'not-featured',
				item_list_name: isFeatured ? 'Kiemelt ajánlataink' : 'Tartós bérlet ajánlataink',

				items: [
					ga4Item
				],

				// matomo
				click: {
					actionField: {
						list: 'Showroom result page',
					},
					products: [
						matomoItem,
					]
				}
			}
		});
	};

	viewItemCar = (product: any) => {
		const googleItem = this._getGoogleProductPayload(product);
		const facebookItem = this._getFacebookProductPayload(product);
		const matomoItem = this._getMatomoProductPayload(product);
		const ga4Item = this._getGa4ProductPayload(product);

		console.warn('Analytics.viewItemCar()', matomoItem, ga4Item);

		/*
		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				'view_item',
				{
					items: [
						googleItem,
					],
					value: this._getPrice(product),
					currency: 'HUF',
				},
			);

			(window as any).gtag(
				'event',
				'view',
				{
					event_category: 'Unique_offer',
					event_label: product.slug
				},
			);
		}
		*/

		if ((window as any).fbq) {
			(window as any).fbq(
				'track',
				'ViewContent',
				facebookItem
			);
		}

		this._dataLayer({
			event: 'productDetail',
			ecommerce: {
				currencyCode: 'HUF',
				value: this._getPrice(product),

				// ga4
				items: [
					ga4Item,
				],

				// matomo
				detail: {
					actionField: {
						list: '',
					},
					products: [
						matomoItem,
					]
				}
			}
		});
	};

	viewItemFlexiplan = (product: any) => {
		const googleItem = this._getGoogleProductPayload(product);
		const facebookItem = this._getFacebookProductPayload(product);
		const matomoItem = this._getMatomoProductPayload(product);
		const ga4Item = this._getGa4ProductPayload(product);

		console.warn('Analytics.viewItemFlexiplan()', product, matomoItem, ga4Item);

		/*
		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				'view_item',
				{
					items: [
						googleItem,
					],
					value: product.fields.price_monthly * product.fleet_size,
					currency: 'HUF',
				},
			);
		}
		*/

		if ((window as any).fbq) {
			(window as any).fbq(
				'track',
				'ViewContent',
				facebookItem
			);
		}

		this._dataLayer({
			event: 'productDetail',
			ecommerce: {
				currencyCode: 'HUF',
				value: this._getPrice(product),

				// ga4
				items: [
					ga4Item,
				],

				// matomo
				detail: {
					actionField: {
						list: '',
					},
					products: [
						matomoItem,
					]
				}
			}
		});
	};

	addToCartCar = (product: any) => {
		const googleItem = this._getGoogleProductPayload(product);
		const facebookItem = this._getFacebookProductPayload(product);
		const matomoItem = this._getMatomoProductPayload(product);
		const ga4Item = this._getGa4ProductPayload(product);

		console.warn('Analytics.addToCartCar()', matomoItem, ga4Item);

		/*
		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				'add_to_cart',
				{
					items: [
						googleItem,
					],
					value: this._getPrice(product),
					currency: 'HUF',
				},
			);

			(window as any).gtag(
				'event',
				'Unique_offer',
				{
					event_category: 'buttonclick',
					event_label:	'Add_to_cart|' +
							product.duration + '|' +
							product.slug + '|' +
							product.downpaymentOption

				},
			);
		}
		*/

		if ((window as any).fbq) {
			(window as any).fbq(
				'track',
				'AddToCart',
				facebookItem
			);
		}

		this._dataLayer({
			event: 'addToCart',
			ecommerce: {
				currencyCode: 'HUF',
				value: this._getPrice(product),

				// ga4
				items: [
					ga4Item,
				],

				// matomo
				add: {
					actionField: {
						list: '',
					},
					products: [
						matomoItem,
					]
				},
			}
		});
	};

	addToCartFlexiplan = (product: any) => {
		const googleItem = this._getGoogleProductPayload(product);
		const facebookItem = this._getFacebookProductPayload(product);
		const matomoItem = this._getMatomoProductPayload(product);
		const ga4Item = this._getGa4ProductPayload(product);

		console.warn('Analytics.addToCartFlexiplan()', matomoItem, ga4Item);

		/*
		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				'add_to_cart',
				{
					items: [
						googleItem,
					],
					value: product.fields.price_monthly * product.fleet_size,
					currency: 'HUF',
				},
			);
		}

		if ((window as any).fbq) {
			(window as any).fbq(
				'track',
				'AddToCart',
				facebookItem
			);
		}
		*/

		this._dataLayer({
			event: 'addToCart',
			ecommerce: {
				currencyCode: 'HUF',
				value: this._getPrice(product),

				// ga4
				items: [
					ga4Item,
				],

				// matomo
				add: {
					actionField: {
						list: '',
					},
					products: [
						matomoItem,
					]
				}
			}
		});
	};

	removeFromCart = (product: any) => {
		const matomoItem = this._getMatomoProductPayload(product);
		const ga4Item = this._getGa4ProductPayload(product);

		console.warn('Analytics.removeFromCart()', matomoItem, ga4Item);

		/*
		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				'remove_from_cart',
				{
					items: [
						item,
					],
					value: this._getPrice(product),
					currency: 'HUF',
				},
			);
		}

		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				'click',
				{
					event_category: 'Checkout',
					event_label: 'Remove_offer',
				},
			);
		}
		*/

		this._dataLayer({
			event: 'removeFromCart',
			ecommerce: {
				currencyCode: 'HUF',
				value: this._getPrice(product),

				// ga4
				items: [
					ga4Item,
				],

				// matomo
				remove: {
					actionField: {
						list: '',
					},
					products: [
						matomoItem,
					]
				},
			}
		});
	};

	beginCheckout = (savedOffers: any[]) => {
		// const googleItems = savedOffers.map(this._getGoogleProductPayload);
		const matomoItems = this._getMatomoProductsPayload(savedOffers);
		const ga4Items = this._getGa4ProductsPayload(savedOffers);

		console.warn('Analytics.beginCheckout()', matomoItems, ga4Items);

		/*
		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				'begin_checkout',
				{
					items: googleItems,
					value: this._getTotalPrice(googleItems),
					currency: 'HUF'
				},
			);
		}

		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				'view',
				{
					event_category: 'Checkout',
				},
			);
		}
		*/

		if ((window as any).fbq) {
			for (const offer of savedOffers) {
				(window as any).fbq(
					'track',
					'InitiateCheckout',
					this._getFacebookProductPayload(offer)
				);
			}
		}

		this._dataLayer({
			event: 'checkout',
			ecommerce: {
				currencyCode: 'HUF',
				value: this._getTotalDurationPrice(matomoItems),

				// ga4
				items: ga4Items,

				// matomo
				products: matomoItems,
				checkout: {
					actionField: {
						step: 1,
					},
				},
			}
		});
	};

	purchase = (payload: any, savedOffers: any[], transactionID: number) => {
		// const googleItems = savedOffers.map(this._getGoogleProductPayload);
		const matomoItems = this._getMatomoProductsPayload(savedOffers);
		const ga4Items = this._getGa4ProductsPayload(savedOffers);

		console.warn('Analytics.purchase()', matomoItems, ga4Items);

		/*
		if ((window as any).gtag) {
			(window as any).gtag(
				'set',
				'user_data',
				{
					email: payload.email,
					phone_number: '+36' + payload.phone_number,
				},
			);

			(window as any).gtag(
				'event',
				'purchase',
				{
					transaction_id: transactionID,
					items: googleItems,
					value: this._getTotalPrice(googleItems),
					currency: 'HUF'
				},
			);

			for (const offer of savedOffers) {
				(window as any).gtag(
					'event',
					'send',
					{
						event_category: 'Checkout',
						event_label:	'Send_enquiry|' +
								offer.duration + '|' +
								offer.car_slug + '|' +
								offer.payment_type
					},
				);
			}
		}
		*/

		if ((window as any).fbq) {
			for (const offer of savedOffers) {
				(window as any).fbq(
					'track',
					'Purchase',
					this._getFacebookProductPayload(offer)
				);
			}
		}

		this._dataLayer({
			event: 'thankyou',
			ecommerce: {
				currencyCode: 'HUF',
				value: this._getTotalPrice(matomoItems),
				transaction_id: transactionID,

				// ga4
				items: ga4Items,

				// matomo
				products: matomoItems,
				purchase: {
					actionField: {
						id: transactionID,
						affiliation: payload.email,
						revenue: this._getTotalPrice(matomoItems),
						tax: 0,
						shipping: 0,
						company: payload.company_name,
						vat: payload.vat_number,
						partnership: payload.joint_venture ? 'yes' : 'no',
						existing: payload.existing_customer ? 'yes' : 'no',
						phone: '+36' + payload.phone_number,
						firstname: payload.first_name,
						surname: payload.last_name,
						newsletter: payload.newsletter ? 'yes' : 'no',
					},
				},
			}
		});
	};

	callbackRequest = (payload: any, id: number, product?: any) => {
		const matomoItem = product ? this._getMatomoProductPayload(product) : undefined;
		const ga4Item = product ? this._getGa4ProductPayload(product) : undefined;

		console.warn('Analytics.callbackRequest()', payload, matomoItem, ga4Item);

		/*
		if ((window as any).gtag) {
			(window as any).gtag(
				'set',
				'user_data',
				{
					email: payload.email,
					phone_number: '+36' + payload.phone_number,
				},
			);

			(window as any).gtag(
				'event',
				'buttonclick',
				{
					event_category: 'Unique_offer',
					event_label: 'Call_back_request',
				},
			);
		}
		*/

		if ((window as any).fbq) {
			(window as any).fbq(
				'track',
				'Lead',
			);
		}

		this._dataLayer({
			event: 'formEvent',
			component: 'lead',
			component_event_type: 'click',
			data: [{
				component_type: 'callback',
				component_title: 'Visszahivas',
				component_link_type: 'button',
				component_link_text: 'Visszahívas',
				component_link_url: window.location.href
			}],
			formName: 'Callback',
			formContext: 'business',
			formLeadStatus: 'n/a', // UQL, MQL, SQL, n/a
			formType: 'callback',
			formStep: 'submit',
			formTitle: 'Visszahivas',

			ecommerce: {
				currencyCode: 'HUF',
				value: this._getPrice(product),
				transaction_id: 'c_' + id,

				// ga4
				items: [
					ga4Item,
				],

				// matomo
				products: [
					matomoItem,
				],
				purchase: {
					actionField: {
						id: 'c_' + id,
						affiliation: payload.email,
						revenue: this._getPrice(product),
						tax: 0,
						shipping: 0,
						company: payload.company_name,
						phone: '+36' + payload.phone_number,
						firstname: payload.name.split(' ').slice(-1).join(' '),
						surname: payload.name.split(' ').slice(0, -1).join(' '),
					},
				},
			},
		});
	};

	filter = (searchParam: string, searchValue: string, payload: any) => {
		console.warn('Analytics.filter()', searchParam, searchValue, payload);

		/*
		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				'Filter',
				{
					event_category: searchParam,
					event_label: searchValue,
					value: 0,
				},
			);
		}

		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				searchParam,
				{
					event_category: 'Cars_filter',
					event_label: searchValue,
				},
			);
		}
		*/

		this._dataLayer({
			event: 'search_result',
			component: 'search_result',
			component_type: 'search_filterblock',
			search_location: 'filter block',
			search_term: payload.gyarto,
			search_term2: payload.modell,
			search_term3: payload.uzemanyag,
			search_term4: payload.karosszeria,
			//'search_results': '10', //total number of results
			search_scope: 'Business', //business or private [static content]
		});
	};

	clickArticle = (title: string, url: string, position: number) => {
		console.warn('Analytics.clickArticle()', title, url, position);

		this._dataLayer({
			event: 'article_lane',
			component: 'content_article_lane',
			component_event_type: 'click',
			data: [{
				component_type: 'article_card',
				component_title: title,
				component_link_type: 'card',
				component_link_text: title,
				component_link_url: url,
				component_position: position,
			}],
		});
	};

	clickMenu = (text: string, url: string) => {
		console.warn('Analytics.clickMenu()', text, url);

		this._dataLayer({
			event: 'internal_navigation',
			component: 'main_menu',
			component_event_type: 'click',
			data: [{
				component_type: 'main_menu_top_link',
				component_title: 'main menu',
				component_link_type: 'text',
				component_link_text: text,
				component_link_url: url,
			}]
		});
	};

	clickBreadcrumb = (clickedText: string, clickedPosition: number, activeBreadcrumb: string) => {
		console.warn('Analytics.clickBreadcrumb()', clickedText, clickedPosition, activeBreadcrumb);

		this._dataLayer({
			event: 'internal_navigation',
			component: 'breadcrumb',
			component_event_type: 'click',
			data: [{
				component_type: 'breadcrumb',
				component_link_type: 'text',
				breadcrumb: clickedText,
				current_breadcrumb: activeBreadcrumb,
				component_position: clickedPosition // home = 1, business lease = 2  cars = 3
			}]
		});
	};

	clickPhoneCall = (phoneNumber: string) => {
		console.warn('Analytics.clickPhoneCall()', phoneNumber);

		this._dataLayer({
			event: 'call',
			component: 'lead',
			component_event_type: 'click',
			data: [{
				component_type: 'assistance_call',
				component_title: 'telefonhivas',
				component_link_type: 'button',
				component_link_text: phoneNumber,
				component_link_url: 'tel:' + phoneNumber.replace(/[^\d]/g, ''),
			}]
		});
	};

	clickContactPage = () => {
		console.warn('Analytics.clickContactPage()');

		this._dataLayer({
			event: 'key page',
			component: 'lead',
			component_event_type: 'click',
			data: [{
				component_type: 'contact_page',
				component_title: 'Kapcsolat',
				component_link_type: 'button',
				component_link_text: 'Kapcsolat',
				component_link_url: 'https://www.leaseplan.com/hu-hu/kapcsolat/',
			}]
		});
	};

	/*
	newsletter = () => {
		console.warn('Analytics.newsletter()');

		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				'checkbox',
				{
					event_category: 'Checkout',
					event_label: 'Subscribe_to_newsletter'
				}

			);
		}
	};
	*/

	/*
	downpaymentChange = (downpaymentOption: any) => {
		console.warn('Analytics.downpaymentChange()', downpaymentOption);

		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				'downpayment_option',
				{
					event_category: 'Unique_offer',
					event_label: downpaymentOption,
				},
			);
		}
	};
	*/

	/*
	incentive = (position: string) => {
		console.warn('Analytics.incentive()', position);

		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				'click',
				{
					event_category: 'Incentive',
					event_label: position,
				},
			);
		}
	};
	*/

	/*
	universalEvent = (data: { category: string; action: string; label: string}) => {
		console.warn('Analytics.universalEvent()', data);

		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				data.action,
				{
					event_category: data.category || '',
					event_label: data.label || '',
				},
			);
		}
	};
	*/

	/*
	rawEvent = (action: string, payload: any) => {
		console.warn('Analytics.rawEvent()', action, payload);

		if ((window as any).gtag) {
			(window as any).gtag(
				'event',
				action,
				payload,
			);
		}
	};
	*/

	private _dataLayer = (...args: any) => {
		const _dl = (window as any).dataLayer;

		if (_dl !== undefined) {
			_dl.push(function() {
				this.reset();
			});

			_dl.push(...args);
		}
	};

	private _getID = (id: number, downpaymentOption: string) => id + (downpaymentOption === 'without_downpayment' ? '_0' : '');

	private _getName = (fields: any) => fields.make + ' ' + fields.type + (fields.subtype ? ' (' + fields.subtype + ')' : '');

	private _getGoogleProductPayload = (product: any) => {
		const offerDetails = product.offer_details || product;
		const fields = offerDetails.fields;
		const downpayment = product.payment_type || product.downpaymentOption;

		if (offerDetails.type === 'car') {
			return {
				id: this._getID(offerDetails.ID, downpayment),
				name: this._getName(fields),
				variant: downpayment,
				brand: fields.make,
				category: 'car',
				price: this._getPrice(product),
				quantity: 1,
			};
		} else if (offerDetails.type === 'flexi') {
			return {
				id: offerDetails.ID,
				name: offerDetails.title,
				variant: fields.cars,
				category: 'flexi',
				price: this._getPrice(product),
				quantity: product.fleet_size || 1,
			};
		}
	};

	private _getFacebookProductPayload = (product) => {
		const offerDetails = product.offer_details || product;
		const fields = offerDetails.fields;
		const downpayment = product.payment_type || product.downpaymentOption;

		if (offerDetails.type === 'car') {
			return {
				content_ids: [this._getID(offerDetails.ID, downpayment)],
				content_name: this._getName(fields),
				content_type: 'product',
				content_category: 'car',
				value: this._getPrice(product),
				currency: 'HUF',
			};
		} else if (offerDetails.type === 'flexi') {
			return {
				content_ids: [this._getID(offerDetails.ID, downpayment)],
				content_name: offerDetails.title,
				content_type: 'product',
				content_category: 'flexi',
				value: this._getPrice(product),
				currency: 'HUF',
			};
		}
	};

	private _getGa4ProductsPayload = (products: any[]) => {
		const ga4Products = {};

		products.forEach((product) => {
			const ga4Product = this._getGa4ProductPayload(product);

			if (!ga4Products[ga4Product.item_id]) {
				ga4Products[ga4Product.item_id] = ga4Product;
			} else {
				ga4Products[ga4Product.item_id].quantity++;
			}
		});

		return Object.values(ga4Products);
	};

	private _getGa4ProductPayload = (product: any) => {
		const offerDetails = product.offer_details || product;
		const fields = offerDetails.fields;
		const downpayment = product.payment_type || product.downpaymentOption;

		if (offerDetails.type === 'car') {
			return {
				item_id: this._getID(offerDetails.ID, downpayment),
				item_sku: this._getID(offerDetails.ID, downpayment),
				item_name: this._getName(fields),
				item_brand: fields.make,

				price: this._getPrice(product),
				quantity: 1,

				item_category: downpayment === 'without_downpayment' ? 'Indulo befizetes nelkul' : 'Indulo befizetessel',
				item_category2: fields.body,
				item_category3: fields.fuel_type,
			};
		} else if (offerDetails.type === 'flexi') {
			return {
				item_id: offerDetails.ID,
				item_sku: offerDetails.ID,
				item_name: offerDetails.title,
				item_category: 'Flex',

				price: this._getPrice(product),
				quantity: product.fleet_size || 1,
			};
		}
	};

	private _getMatomoProductsPayload = (products: any[]) => {
		const matomoProducts = {};

		products.forEach((product) => {
			const matomoProduct = this._getMatomoProductPayload(product);

			if (!matomoProducts[matomoProduct.id]) {
				matomoProducts[matomoProduct.id] = matomoProduct;
			} else {
				matomoProducts[matomoProduct.id].quantity++;
			}
		});

		return Object.values(matomoProducts);
	};

	private _getMatomoProductPayload = (product: any) => {
		const offerDetails = product.offer_details || product;
		const fields = offerDetails.fields;
		const downpayment = product.payment_type || product.downpaymentOption;

		if (offerDetails.type === 'car') {
			return {
				id: this._getID(offerDetails.ID, downpayment),
				name: this._getName(fields),
				brand: fields.make,
				category: downpayment === 'without_downpayment' ? 'Indulo befizetes nelkul' : 'Indulo befizetessel',
				price: this._getPrice(product),
				quantity: 1,
				dimension1: fields.body,
				dimension2: fields.fuel_type,
				dimension3: fields.gear_type,
				dimension5: product.mileage,
				dimension6: fields.available_colors,
				dimension7: product.duration,
				dimension8: fields.performance_hp + ' HP',
				dimension22: 'new car',
				dimension44: 'Online Checkout',
				metric20: product.duration ? (parseFloat(this._getPrice(product)) * product.duration) + '.00' : undefined,
			};
		} else if (offerDetails.type === 'flexi') {
			return {
				id: offerDetails.ID,
				name: offerDetails.title,
				category: 'Flex',
				price: this._getPrice(product),
				quantity: product.fleet_size || 1,
			};
		}
	};

	private _getPrice = (product: any) => {
		const offerDetails = product.offer_details || product;
		const fields = offerDetails.fields;
		const downpayment = product.payment_type || product.downpaymentOption;

		if (offerDetails.type === 'car') {
			if (downpayment === undefined || downpayment === 'with_downpayment') {
				return fields.price_with_downpayment + '.00';
			} else {
				return fields.price_without_downpayment + '.00';
			}
		} else {
			return fields.price_monthly + '.00';
		}
	};

	private _getTotalPrice = (products: any[]) => products.reduce((sum, product) => sum +
				parseInt(product.price, 10) * parseInt(product.quantity, 10), 0) + '.00';

	private _getTotalDurationPrice = (products: any[]) => products.reduce((sum, product) => sum +
				parseInt(product.price, 10) * parseInt(product.quantity, 10) * (product.duration || 1), 0) + '.00';
}

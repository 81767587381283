import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


import { hmrBootstrap } from './hmr';

// import { create } from 'rxjs-spy';
// if (!environment.production) {
// 	const spy = create();
// 	spy.log();
// }

if (environment.production) {
	enableProdMode();

	if (environment.APP_ENV !== 'staging') {
		[
			'debug', 'log', 'info', 'warn', 'error',
			'group', 'groupCollapsed', 'groupEnd',
			'dir', 'dirxml', 'trace', 'profile'
		].forEach(method => window.console[method] = () => void 0);
	}
}

const bootstrap = () => platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.catch((err) => console.log(err));

if (environment.hmr) {
	if ((module as any).hot) {
		hmrBootstrap(module, bootstrap);
	} else {
		console.error('HMR is not enabled!');
	}
} else {
	document.addEventListener('DOMContentLoaded', () => {
		console.log('DOMContentLoaded');
		bootstrap();
	});
}

import { Action } from '@ngrx/store';

export const FLEXIPLAN_LOAD = '[Flexiplan] load';
export const FLEXIPLAN_LOAD_SUCCESS = '[Flexiplan] load success';
export const FLEXIPLAN_LOAD_FAILURE = '[Flexiplan] load failure';

export class FlexiplanLoadAction implements Action {
	public readonly type = FLEXIPLAN_LOAD;
}

export class FlexiplanLoadSuccessAction implements Action {
	public readonly type = FLEXIPLAN_LOAD_SUCCESS;
	constructor(public payload: any) {}
}

export class FlexiplanLoadFailureAction implements Action {
	public readonly type = FLEXIPLAN_LOAD_FAILURE;
}

export type FlexiplanActions
	= FlexiplanLoadAction
	| FlexiplanLoadSuccessAction
	| FlexiplanLoadFailureAction;

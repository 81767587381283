import { NotFoundComponent } from './404/404.component';
import { ErrorComponent } from './error/error.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { OffersSummaryBtnComponent } from './offers-summary-btn/offers-summary-btn.component';
import { NotificationComponent } from './notification/notification.component';

export const SHARED_ENTRY_COMPONENTS = [
];

export const SHARED_COMPONENTS = [
	...SHARED_ENTRY_COMPONENTS,
	HeaderComponent,
	FooterComponent,
	NotFoundComponent,
	ErrorComponent,
	OffersSummaryBtnComponent,
	NotificationComponent
];

export * from './header/header.component';
export * from './footer/footer.component';
export * from './404/404.component';
export * from './error/error.component';
export * from './offers-summary-btn/offers-summary-btn.component';
export * from './notification/notification.component';

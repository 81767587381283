import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import {
	ActivatedRoute,
	GuardsCheckEnd,
	GuardsCheckStart,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	ResolveEnd,
	ResolveStart,
	Router,
	RouterOutlet,
} from '@angular/router';
import { SharedStore } from '@app-core/services/shared';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { AnalyticsService } from './leaseplan/services/analytics.service';



export const routeAnimations = trigger('routeAnimations', [
	transition('* <=> *', [
		query(':enter', [
			style({ opacity: 0 }),
			// animate('300ms ease-in', style({ opacity: 0 }))
		], { optional: true }),

		group([
			query(':leave', [
				style({ opacity: 1 }),
				animate('300ms ease-in-out', style({ opacity: 0.1 }))
			], { optional: true }),

			query(':enter', [
				style({ opacity: 0 }),
				animate('300ms ease-in-out', style({ opacity: 1 }))
			], { optional: true }),
		]),

	]),
]);


@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'app',
	templateUrl: 'app.component.html',
	animations: [
		routeAnimations
	],
})
export class AppComponent implements OnInit, OnDestroy {
	// @HostBinding('class') public cssClass = 'home';

	public loadInProgress: boolean = true;
	private _loadInProgressCancelTimeout;

	constructor(
		router: Router,
		ngbNavConfig: NgbNavConfig,
		public shared: SharedStore,
		private _activatedRoute: ActivatedRoute,
		private _analyticsService: AnalyticsService
	) {

		ngbNavConfig.animation = false;

		router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				// console.log('AppComponent', 'NavigationStart', event);

				clearTimeout(this._loadInProgressCancelTimeout);
				this.loadInProgress = true;

			} else if (event instanceof GuardsCheckStart) {
				// console.log(event);
			} else if (event instanceof GuardsCheckEnd) {
				// console.log(event);
			} else if (event instanceof ResolveStart) {
				// console.log(event);
			} else if (event instanceof ResolveEnd) {
				// console.log(event);
			} else if (event instanceof NavigationEnd) {
				// console.log('AppComponent', 'NavigationEnd', event);



				clearTimeout(this._loadInProgressCancelTimeout);

				this._loadInProgressCancelTimeout = setTimeout(() => {
					this.loadInProgress = false;
				}, 500);



				let disablePageView = false;
				let child = this._activatedRoute.firstChild;

				while (child) {
					if (child.snapshot.data?.disable_page_view) {
						disablePageView = true;
					}

					child = child.firstChild;
				}

				if (!disablePageView) {
					this._analyticsService.pageView();
				}

				// this.cssClass = 'page-' + this.shared.page;
			} else if (event instanceof NavigationCancel) {
				// console.log('AppComponent', 'NavigationCancel', event);

				clearTimeout(this._loadInProgressCancelTimeout);

				this._loadInProgressCancelTimeout = setTimeout(() => {
					this.loadInProgress = false;
				}, 500);
			} else if (event instanceof NavigationError) {
				// console.log('AppComponent', 'NavigationError', event);

				clearTimeout(this._loadInProgressCancelTimeout);

				this._loadInProgressCancelTimeout = setTimeout(() => {
					this.loadInProgress = false;
				}, 500);
			}
		});
	}

	ngOnInit() {
		// Test via a getter in the options object to see if the passive property is accessed
		let supportsPassive = false;
		try {
			const opts = Object.defineProperty({}, 'passive', {
				get: () => {
					supportsPassive = true;
				}
			});
			window.addEventListener('testPassive', null, opts);
			window.removeEventListener('testPassive', null, opts);
		} catch (e) {
			//
		}
		// Use our detect's results. passive applied if supported, capture will be false either way.
		document.body.addEventListener('touchstart', () => void 0, supportsPassive ? { passive: true } : false);
	}

	ngOnDestroy() {
		//
	}

	prepareRoute(outlet: RouterOutlet) {
		if (outlet && outlet.isActivated) {
			return outlet.activatedRoute;
		}
	}
}

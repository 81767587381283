import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromFilter from './filter.reducers';
import * as fromSavedOffers from './saved-offers.reducers';
import * as fromEnquiryForm from './enquiry.reducers';
import * as fromFlexiplan from './flexiplan.reducers';

export { State as FilterState } from './filter.reducers';
export { State as SavedOffersState } from './saved-offers.reducers';
export { State as EnquiryFormState } from './enquiry.reducers';
export { State as FlexiplanState } from './flexiplan.reducers';

export interface State {
	filter: fromFilter.State;
	saved_offers: fromSavedOffers.State;
	enquiry: fromEnquiryForm.State;
	flexiplan: fromFlexiplan.State;
}

export const reducers: ActionReducerMap<State> = {
	filter: fromFilter.reducer,
	saved_offers: fromSavedOffers.reducer,
	enquiry: fromEnquiryForm.reducer,
	flexiplan: fromFlexiplan.reducer,
};

const getState = createFeatureSelector<State>('offers');

export const getFilterState = createSelector(
	getState,
	(state: State) => state.filter
);

export const getSavedOffersState = createSelector(
	getState,
	(state: State) => state.saved_offers
);

export const getEnquiryFormState = createSelector(
	getState,
	(state: State) => state.enquiry
);

export const getFlexiplanState = createSelector(
	getState,
	(state: State) => state.flexiplan.items
);

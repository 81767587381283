import * as fromEnquiryForm from '../actions/enquiry.actions';
import { Dictionary } from '@app/cms/models/wp-resource.model';


export type State = Dictionary<string>;

const initialState: State = {};


export function reducer(state = initialState, action: fromEnquiryForm.EnquiryFormActions): State {
	if (!action) {
		return state;
	}

	switch (action.type) {

		case fromEnquiryForm.ENQUIRY_FORM_UPDATE: {
			const data: any = {};

			if (action.payload.last_name) {
				data.last_name = action.payload.last_name;
			}

			if (action.payload.first_name) {
				data.first_name = action.payload.first_name;
			}

			if (action.payload.email) {
				data.email = action.payload.email;
			}

			if (action.payload.phone_number) {
				data.phone_number = action.payload.phone_number;
			}

			if (action.payload.company_name) {
				data.company_name = action.payload.company_name;
			}

			if (action.payload.vat_number) {
				data.vat_number = action.payload.vat_number;
			}

			if (action.payload.joint_venture) {
				data.joint_venture = action.payload.joint_venture;
			}

			if (action.payload.existing_customer) {
				data.existing_customer = action.payload.existing_customer;
			}

			/*
			if (action.payload.min_closed_years) {
				data.min_closed_years = action.payload.min_closed_years;
			}
			*/

			if (action.payload.cars_number) {
				data.cars_number = action.payload.cars_number;
			}

			if (action.payload.comments) {
				data.comments = action.payload.comments;
			}

			return data;
		}

		case fromEnquiryForm.ENQUIRY_FORM_DELETE:
			return {};

		default:
			return state;
	}
}

import { ApplicationRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
	providedIn: 'root',
})
export class NotificationDisplayService {
	constructor(
		private _app: ApplicationRef
	) {
	}

	open = new BehaviorSubject(false);
	modifying = new BehaviorSubject(false);

	setOpen(state) {
		this.open.next(state);
	}

	setModifying(state) {
		this.modifying.next(state);
	}
}

import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';
import { localStorageSync } from 'ngrx-store-localstorage';

import { environment } from '@app-env/environment';


export function logger(reducer) {
	return function newReducer(state, action) {
		console.groupCollapsed(action.type);
		const prevState = { ...state };
		const nextState = reducer(state, action);
		console.log(`%c prev state`, `color: #9E9E9E; font-weight: bold`, prevState);
		console.log(`%c action`, `color: #03A9F4; font-weight: bold`, action);
		console.log(`%c next state`, `color: #4CAF50; font-weight: bold`, nextState);
		console.groupEnd();
		return nextState;
	};
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
	return localStorageSync({
		keys: [{
			offers: [ 'saved_offers', 'enquiry'],
			}],
		rehydrate: true,
	})(reducer);
}

const metaReducers: MetaReducer<{}>[] = [localStorageSyncReducer];

if (!environment.production) metaReducers.push(logger, storeFreeze);

export const NGRX_CONFIG = [
	StoreModule.forRoot(
		{}, { metaReducers }
	),
	EffectsModule.forRoot(
		[]
	),
	StoreDevtoolsModule.instrument({
		maxAge: 25, // Retains last 25 states
		// logOnly: environment.production, // Restrict extension to log-only mode
	}),

];


import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { SavedOffer } from '@app/leaseplan/models';

export const OFFERS_UPSERT = '[Saved offers] update';
export const OFFERS_DELETE = '[Saved offers] delete';
export const OFFERS_DELETE_ALL = '[Saved offers] delete all';

export class SavedOffersUpsertAction implements Action {
	public readonly type = OFFERS_UPSERT;
	constructor(public payload: { offer: SavedOffer }) {}
}

export class SavedOffersDeleteAction implements Action {
	public readonly type = OFFERS_DELETE;
	constructor(public payload: { id: string }) {}
}

export class SavedOffersDeleteAllAction implements Action {
	public readonly type = OFFERS_DELETE_ALL;
}

export type SavedOffersActions
	= SavedOffersUpsertAction
	| SavedOffersDeleteAction
	| SavedOffersDeleteAllAction
;

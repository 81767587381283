import { createSelector } from '@ngrx/store';

import * as fromOffers from '../reducers';
import * as fromSavedOffers from '../reducers/saved-offers.reducers';

export const {
	selectEntities,
	selectAll,
	selectTotal,
} = fromSavedOffers.adapter.getSelectors(fromOffers.getSavedOffersState);

export const selectOne = (id: string) => createSelector(
	selectEntities,
	(offers) => offers[id]
);

import * as fromFilter from '../actions/filter.actions';
import { Dictionary } from '@app/cms/models/wp-resource.model';
import { Payload } from '@app/leaseplan/models';


export type State = Dictionary<string>;

const initialState: State = {};


export function reducer(state = initialState, action: fromFilter.OffersFilterActions): State {
	if (!action) {
		return state;
	}

	switch (action.type) {

		case fromFilter.OFFERS_SET_FILTER:
			return {
				// ...state,
				...action.payload
			};

		default:
			return state;
	}
}

export const environment = {
	production: true,
	hmr: false,

	APP_NAME: 'autotartosberlet',
	APP_ENV: 'master',
	COMMIT_HASH: 'ae02f5c5fd91aa622aebb53c18bea02987c0f1f8',
	API_URL: '/api/',
	SERVICE_SENTRY_DSN_PUBLIC: 'https://8346eda2414d42b1b57fcdaac6ef20ce@sentry.io/1450888',
	SERVICE_RECAPTCHA_KEY: '6LdZSaIUAAAAAPaPUaw4BpQxIlsjpkPbJ1YNvgKn',
};

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as flexiplanActions from '../actions/flexiplan.actions';
import { FlexiplanService } from '@app/leaseplan/services/flexiplan.service';


@Injectable()
export class FlexiplanEffects {

	constructor(
		private actions$: Actions,
		private flexiplanService: FlexiplanService,
	) {}

	loadMovies$ = createEffect(() => this.actions$.pipe(
		ofType(flexiplanActions.FLEXIPLAN_LOAD),
		mergeMap(() => this.flexiplanService.getFlexiplans()
			.pipe(
				map(items => ({ type: flexiplanActions.FLEXIPLAN_LOAD_SUCCESS, payload: items })),
				catchError((error) => {
					console.log(error);

					return EMPTY;
				})
			))
		)
	);
}

import { Component, HostListener, HostBinding, ChangeDetectorRef, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';
import { FilterService } from '@app/leaseplan/services/filter.service';
import { Title, Meta } from '@angular/platform-browser';
import { AnalyticsService } from '@app/leaseplan/services/analytics.service';


@Component({
	selector: 'header',
	templateUrl: 'header.component.html',
	styleUrls: ['header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('smoothCollapse', [
			state('initial', style({
				height: 0,
				opacity: 0
			})),
			state('final', style({
				height: '100vh',
				opacity: 1
			})),
			transition('initial => final', animate('250ms ease-in-out')),
			transition('final => initial', animate('250ms ease-in-out'))
		]),
	]
})
export class HeaderComponent implements OnInit, OnDestroy {

	public isNavbarCollapsed: boolean = true;
	public isHomeActive: boolean = false;
	public _originalTitle: string;
	public _originalOgTitle: string;

	private _routerEventsSubscription: Subscription;

	constructor(
		private _router: Router,
		private _cd: ChangeDetectorRef,
		private filterService: FilterService,
		private _title: Title,
		private _meta: Meta,
		private _analytics: AnalyticsService
	) {
	}

	@HostBinding('class.collapsed')
	private headerCollapsed: boolean = false;

	@HostListener('window:scroll', [])
	checkScroll() {
		this.headerCollapsed = window.pageYOffset >= 15;
	}

	ngOnInit() {
		this._originalTitle = this._title.getTitle();
		this._originalOgTitle = this._meta.getTag('property="og:title"').content;

		this._routerEventsSubscription = this._router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.isNavbarCollapsed = true;
			}

			this.isHomeActive = (
				this._router.isActive('/autok', false) ||
				this._router.isActive('/promocio', false) ||
				this._router.isActive('/', true) ||
				this._router.url.startsWith('/?indulo-befizetes=') ||
				(
					window.innerWidth >= 992 &&
					this._router.isActive('/ajanlatkeres', true)
				)
			);

			this._cd.markForCheck();
		});
	}

	clearFilter() {
		this.filterService.payload.next({
			'gyarto': null,
			'modell': null,
			'uzemanyag': null,
			'karosszeria': null,
			'motor': null,
			'valto': null,
			'elerhetoseg': null,
			'havidij': null
		});

		this.filterService.clearForm.next('');


		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});

		this._title.setTitle(this._originalTitle);
		this._meta.updateTag({ property: 'og:title', content: this._originalTitle });
	}

	ngOnDestroy() {
		this._routerEventsSubscription.unsubscribe();
	}

	analyticsClickContactEvent() {
		this._analytics.clickContactPage();
	}

	analyticsClickMenuEvent(text: string, url: string) {
		if (url[0] === '/') {
			url = window.location.origin + url;
		}

		this._analytics.clickMenu(text, url);
	}
}

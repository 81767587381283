import { Component } from '@angular/core';
import { SharedStore } from '@app-core/services/shared';

@Component({
	selector: 'footer',
	templateUrl: 'footer.component.html',
	styleUrls: [ 'footer.component.scss' ],
})
export class FooterComponent {
	constructor(
		public shared: SharedStore,
	) {}
}

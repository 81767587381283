import { NgModule } from '@angular/core';
import { SHARED_COMPONENTS, SHARED_ENTRY_COMPONENTS } from './components';
import { SHARED_PIPES } from './pipes';
import { SHARED_DIRECTIVES } from './directives';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule, NgbCollapseModule, NgbDropdownModule, NgbAccordionModule, NgbDatepickerModule,  } from '@ng-bootstrap/ng-bootstrap';

import { RouterModule } from '@angular/router';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,

		NgbModalModule,
		NgbCollapseModule,
		NgbDropdownModule,
		NgbAccordionModule,
		NgbDatepickerModule,
	],
	declarations: [
		...SHARED_COMPONENTS,
		...SHARED_DIRECTIVES,
		...SHARED_PIPES,
	],
	exports: [
		...SHARED_COMPONENTS,
		...SHARED_DIRECTIVES,
		...SHARED_PIPES,

		NgbCollapseModule,
		NgbAccordionModule,
		NgbDatepickerModule
	],
	entryComponents: [
		...SHARED_ENTRY_COMPONENTS,
	]
})
export class SharedModule {}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromOffers from '@app/leaseplan/store';

@Injectable({ providedIn: 'root' })
export class SavedOffersCounter {

	constructor(
		private _store: Store<fromOffers.State>,
	) {
	}

	public getTotal$() {
		return this._store.select(fromOffers.selectTotal);
	}
}

<a
	*ngxLet="savedOffersTotal$ | async as savedOffersTotal"
	[routerLink]="['/ajanlatkeres']"
	routerLinkActive="active"
	class="offers-nav-link"
	[ngClass]="{'disabled': savedOffersTotal === 0}">
	Ajánlatkérések
	<div *ngIf="(savedOffersTotal$ | async) > 0" class="counter">
		<span>{{ savedOffersTotal$ | async }}</span>
	</div>

	<notification-component class="d-none d-lg-block"></notification-component>
</a>


import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class SharedStore {
	public title: string;
	public page: string;
	public state: string = 'ap';
}

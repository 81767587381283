import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { environment } from '@app-env/environment';

import { NGRX_CONFIG } from './configs/ngrx.config';
import { SENTRY_PROVIDER } from './configs/sentry.config';



@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,

		...NGRX_CONFIG,
	],
	providers: [
		environment.production ? SENTRY_PROVIDER : [],
	]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error('CoreModule has already been loaded. Import Core modules in the AppModule only.');
		}
	}
}

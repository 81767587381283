import { Action } from '@ngrx/store';
import { Dictionary } from '@app/cms/models/wp-resource.model';
import { Payload } from '@app/leaseplan/models';

export const OFFERS_SET_FILTER = '[Offers] set filter';

export class OffersSetFilterAction implements Action {
	public readonly type = OFFERS_SET_FILTER;
	constructor(public payload: Dictionary<string>) {}
}

export type OffersFilterActions
	= OffersSetFilterAction
;

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { SharedModule } from '@app/shared/shared.module';
import { NotFoundComponent } from '@app/shared/components';

const appRoutes: Routes = [
	{
		path: '',
		loadChildren: () => import('./leaseplan/leaseplan.module').then(m => m.LeaseplanModule)
	},
	{
		path: '**',
		component: NotFoundComponent,
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			useHash: false,
			initialNavigation: 'enabled',
			scrollPositionRestoration: 'enabled',
			// enableTracing: true,
			errorHandler: (error: any) => {
				if (error.message && error.message.match(/^Loading chunk \d+ failed\./)) {
					const chunkErrors = parseInt(
						window.localStorage.getItem('chunk-errors') || '0',
						10,
					) + 1;

					window.localStorage.setItem('chunk-errors', '' + chunkErrors);

					setTimeout(() => {
						if (chunkErrors <= 3) {
							window.location.reload();
						} else {
							window.localStorage.removeItem('chunk-errors');
							window.location.href = '/';
						}
					}, 1000);
				}
			},
		}),

		SharedModule
	],
	exports: [
		RouterModule
	],
	providers: [
	]
})

export class AppRoutingModule {}
